import $ from "jquery";
window.jQuery = $;
import jqueryValidation from 'jquery-validation';

require("../node_modules/slicknav/dist/jquery.slicknav.js");

$("header nav ul").slicknav();

$('#next').on('click', function () {
    $("html, body").animate({ scrollTop: $('#items').offset().top }, 600);
})


$('#verstuur').on('click', function (e) {
    e.preventDefault();
    $('#submitfield').click();
})

$('#formulier').validate();

jQuery.extend(jQuery.validator.messages, {
    required: "",
    remote: "Please fix this field.",
    email: "",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    digits: "Please enter only digits.",
    creditcard: "Please enter a valid credit card number.",
    equalTo: "Please enter the same value again.",
    accept: "Please enter a value with a valid extension.",
    maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
    minlength: jQuery.validator.format("Please enter at least {0} characters."),
    rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
    range: jQuery.validator.format("Please enter a value between {0} and {1}."),
    max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
    min: jQuery.validator.format("Please enter a value greater than or equal to {0}.")
});	